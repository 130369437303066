// https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
// https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

require('dotenv').config();

const TRACKING_USER_ID = process.env.REACT_APP_GA_TRACKING_ID;

if (process.env.REACT_APP_DEBUG_TRACKING) {
  ReactGA.initialize(TRACKING_USER_ID, { debug: true });
} else {
  ReactGA.initialize(TRACKING_USER_ID);
}

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
