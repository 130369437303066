import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withTracker } from './utils/analyticsTracker';

const Homepage = lazy(() => import('./pages/homepage/Homepage'));
const Editor = lazy(() => import('./pages/editor/Editor'));
const Viewer = lazy(() => import('./pages/viewer/Viewer'));
const Rooms = lazy(() => import('./pages/rooms/Rooms'));
const CreateRoom = lazy(() => import('./pages/createRoom/CreateRoom'));
const EnterRoom = lazy(() => import('./pages/enterRoom/EnterRoom'));

const PrivacyPolicy = lazy(() =>
  import('./pages/privacy-policy/PrivacyPolicy')
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={withTracker(Homepage)} />
          <Route exact path="/editor" component={withTracker(Editor)} />
          <Route exact path="/viewer" component={withTracker(Viewer)} />
          <Route
            exact
            path="/create-room"
            component={withTracker(CreateRoom)}
          />
          <Route
            exact
            path="/enter-room/:roomId"
            component={withTracker(EnterRoom)}
          />
          <Route exact path="/rooms" component={withTracker(Rooms)} />
          <Route
            exact
            path="/privacy-policy"
            component={withTracker(PrivacyPolicy)}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
